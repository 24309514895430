<template>
  <div>
    <b-row>
      <b-col md="2">
        <b-nav vertical pills>
          <b-nav-item v-for="v in navList" :key="v.key" :active="v.isActive" :href="v.url" active-class="tmprimary">{{v.title}}</b-nav-item>
        </b-nav>
      </b-col>
      <b-col md="10">
        <b-container fluid>
          <router-view />
        </b-container>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ProjectsInfo from "@/datasrc/projects.list.js"
export default {
  name: "projects",
  computed:{
    navList(){
      return ProjectsInfo.getRenderList(this.$route.path)
    }
  }
}
</script>
